import { formatDistanceToNow, isMatch, isToday, parse } from 'date-fns'
import parseDate from './parseDate'

export default function formatDateRelative(dateString: string) {
  const date = parseDate(dateString)

  if (!date) {
    return '-'
  }

  try {
    return formatDistanceToNow(date, { addSuffix: true })
  } catch (error) {
    console.log('failed to format relative date', dateString, date)
    console.error(error)
    return '-'
  }
}
