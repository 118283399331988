import { DashboardCompany } from '@/types'
import { add, formatDistanceStrict, isBefore, isMatch, parse } from 'date-fns'

export default function relativeExpirationInfo(
  ownershipExpiresOn: DashboardCompany['ownershipExpiresOn'],
  nowIsoString?: string
) {
  if (
    !ownershipExpiresOn ||
    String(ownershipExpiresOn).toLocaleLowerCase() === 'null'
  ) {
    return {
      isExpiredOrExpiringSoon: false,
      distanceToExpiration: '',
      isExpired: false,
    }
  }

  let parsedExpirationDate

  try {
    if (isMatch(ownershipExpiresOn, 'yyyy-MM-dd HH:mm:ss.SSS xx')) {
      parsedExpirationDate = parse(
        ownershipExpiresOn,
        'yyyy-MM-dd HH:mm:ss.SSS xx',
        new Date()
      )
    } else {
      parsedExpirationDate = new Date(ownershipExpiresOn)
    }
  } catch (error) {
    console.log(
      '[relativeExpirationInfo] failed to parse date',
      ownershipExpiresOn
    )
    console.error(error)
  }

  if (!parsedExpirationDate) {
    return {
      isExpiredOrExpiringSoon: false,
      distanceToExpiration: '',
      isExpired: false,
    }
  }

  try {
    const now = nowIsoString ? new Date(nowIsoString) : new Date()
    const oneWeekFromNow = add(now, { weeks: 1 })
    const distanceToExpiration = formatDistanceStrict(now, parsedExpirationDate)
    const isExpiringWithinOneWeek = isBefore(
      parsedExpirationDate,
      oneWeekFromNow
    )
    const isExpired = isBefore(parsedExpirationDate, now)

    return {
      isExpiredOrExpiringSoon: isExpiringWithinOneWeek || isExpired,
      distanceToExpiration,
      isExpired,
    }
  } catch (error) {
    console.log(
      '[relativeExpirationInfo] failed to format',
      parsedExpirationDate
    )
    console.error(error)
    return {
      isExpiredOrExpiringSoon: false,
      distanceToExpiration: '',
      isExpired: false,
    }
  }
}
